import { getPreferences } from './Preferences';

let subreddits = [];
let theme = '';

export function set(key, value) {
  if (key === keys.SUBREDDITS) {
    subreddits = value;
  }
  if (key === keys.THEME) {
    theme = value;
  }
  const stringifiedValue = JSON.stringify(value);
  localStorage.setItem(key, stringifiedValue);
}

export function get(key) {
  const value = localStorage.getItem(key);
  const parsedValue = value ? JSON.parse(value) : null;
  return parsedValue;
}

export function getSubreddits() {
  if (subreddits.length === 0) {
    subreddits = get(keys.SUBREDDITS) || [];
  }
  return subreddits;
}

export function getTheme() {
  if (!theme) {
    theme = getPreferences().theme ?? 'system';
  }
  return theme;
}

export const keys = {
  SUBREDDITS: 'subreddits',
  CHANNELS: 'channels',
  SELECTION: 'selection',
  THEME: 'theme',
  EXPANDED_REPLIES: 'exp-replies',
  BIG_THUMBNAILS: 'big-thumbs',
  USERNAME: 'username',
  PREFERENCES: 'prefs',
};

function clear() {
  localStorage.clear();
}

const Store = { get, set, keys, clear };

export default Store;
