import React from 'react';
import PropTypes from 'prop-types';

/**
 * Icons made with simple lines
 *
 * Based on the `type` prop, the proper icon is drawn:
 * Options are: "plus" + ; "check" ✔ ; "cross" x ;
 */

const LineIcon = ({ type, thickness, width, height, className }) => {
  const wrapperStyle = {
    width: width ? `${width}px` : null,
    height: height ? `${height}px` : null,
  };

  const iconStyle = {
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: thickness ? `${thickness}px` : null,
  };

  return (
    <svg viewBox="0 0 16 16" className={className} style={wrapperStyle}>
      <path d={getPath(type, thickness)} style={iconStyle} />
    </svg>
  );
};

const relativeRatio = 2.857142857142857;

const getPath = (type, thickness) => {
  switch (type) {
    case 'plus':
      return 'M8 0 L8 16 M0 8 L16 8';
    case 'cross':
      const relativePoint = thickness / relativeRatio;
      const commands = [
        `M${relativePoint} ${relativePoint}`,
        `L${16 - relativePoint} ${16 - relativePoint}`,
        `M${relativePoint} ${16 - relativePoint}`,
        `L${16 - relativePoint} ${relativePoint}`,
      ];
      return commands.join(' ');
    case 'check':
      return 'M0 8 L6 14 L16 0';
  }
};

LineIcon.propTypes = {
  thickness: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.oneOf(['plus', 'check', 'cross']).isRequired,
};

LineIcon.defaultProps = {
  thickness: 1,
};

export default LineIcon;
