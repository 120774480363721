import React from 'react';
import PropTypes from 'prop-types';
import ActionStore from '../../modules/ActionStore';
import MainButton from '../Button/MainButton';
import SubredditAdder from '../SubredditAdder/SubredditAdder';
import '../ModalForm/ModalForm.scss';
import './ChannelCreator.scss';
import { SUBTYPE } from '../../constants';

class ChannelCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subredditsAdded: cache.subreddits,
      title: cache.title,
      searchResults: [],
      processing: false,
      error: '',
      addingSubreddit: false,
    };
    this.reddit = props.reddit;
    this.titleInput = React.createRef();
    this.searcherInput = React.createRef();
    this.updateTitle = this.updateTitle.bind(this);
    this.saveNewChannel = this.saveNewChannel.bind(this);
  }

  updateTitle(ev) {
    const newValue = ev.target.value;
    this.setState({
      title: newValue,
    });
  }

  saveNewChannel() {
    const { title, subredditsAdded } = this.state;
    if (!title || subredditsAdded.length < 2) {
      this.setState({
        error: title
          ? 'Add at least 2 subreddits'
          : 'Give a title to the channel',
      });
      return;
    }
    this.setState({ error: '', processing: true });
    const channel = {
      name: title,
      subreddits: subredditsAdded,
    };
    this.reddit
      .createMultireddit(channel)
      .then(() => {
        ActionStore.dispatch('channel-added', channel);
        ActionStore.dispatch('switch-subscription', {
          type: SUBTYPE.CHANNEL,
          name: title,
        });
        cache = emptyCache();
        this.props.onComplete();
      })
      .catch(error => {
        this.setState({
          processing: false,
          error: 'Error while creating new Channel. ' + error,
        });
      });
  }

  componentDidMount() {
    this.titleInput.current.focus();
  }

  componentWillUnmount() {
    cache.title = this.state.title;
    cache.subreddits = this.state.subredditsAdded;
  }

  render() {
    const { subredditsAdded } = this.state;

    return (
      <>
        {this.state.addingSubreddit ? (
          <>
            <SubredditAdder
              title="New Channel: Add subreddit"
              reddit={this.reddit}
              onComplete={result => {
                this.setState(prevState => {
                  prevState.subredditsAdded.push(result);
                  return {
                    subredditsAdded: prevState.subredditsAdded,
                    addingSubreddit: false,
                  };
                });
              }}
            />
          </>
        ) : (
          <div
            className="ModalForm"
            onClick={ev => {
              ev.preventDefault();
              ev.stopPropagation();
              return;
            }}
          >
            <label htmlFor="channel-title" className="block mb2">
              Channel name
            </label>
            <input
              type="text"
              id="channel-title"
              className="ModalForm__input mb3"
              value={this.state.title}
              onChange={this.updateTitle}
              placeholder="Name your channel"
              ref={this.titleInput}
              onKeyPress={stopKeyPressPropagation}
            />
            {subredditsAdded.length > 0 && (
              <>
                <div className="mb2">Subreddits Added</div>
                <div className="ChannelCreator__subreddits-list">
                  {subredditsAdded.map(subreddit => (
                    <span key={subreddit} className="ChannelCreator__subreddit">
                      <span>{subreddit}</span>
                      <button
                        className="ChannelCreator__button-remove-subreddit
                                 ml2 flex flex-y flex-x pl0 pr0"
                        onClick={() => {
                          this.setState(prevState => {
                            const index = prevState.subredditsAdded.indexOf(
                              subreddit
                            );
                            prevState.subredditsAdded.splice(index, 1);
                            return {
                              subredditsAdded: prevState.subredditsAdded,
                            };
                          });
                        }}
                      >
                        &times;
                      </button>
                    </span>
                  ))}
                </div>
              </>
            )}
            <MainButton
              onClick={() => {
                this.setState({ addingSubreddit: true, error: '' });
              }}
              className="w100 mb5"
            >
              Add subreddit
            </MainButton>

            {this.state.error && (
              <div className="ChannelCreator__error mb1">
                {this.state.error}
              </div>
            )}

            <MainButton
              onClick={this.saveNewChannel}
              className="w100"
              processing={this.state.processing}
            >
              {this.state.processing
                ? 'Saving new channel...'
                : 'Create channel'}
            </MainButton>
          </div>
        )}
      </>
    );
  }
}

export default ChannelCreator;

ChannelCreator.propTypes = {
  reddit: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

function stopKeyPressPropagation(ev) {
  ev.stopPropagation();
}

let cache = emptyCache();

function emptyCache() {
  return {
    title: '',
    subreddits: [],
  };
}
