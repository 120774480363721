import React from 'react';
import PropTypes from 'prop-types';
import { cc } from '../../modules/styles';

import './Loading.scss';

function Loading({ className }) {
  return <div className={cc('Loading anim-pulse', className)} />;
}

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
