import React from 'react';
import BarButton from './BarButton';
import LineIcon from '../Icons/LineIcon';
import { closePost } from '../../actions';

import './DetailsBar.scss';
import { useMatchMedia } from '../../hooks/useMatchMedia';

const DetailsBar = ({ title }) => {
  const isWideViewport = useMatchMedia('(min-width: 640px)');

  if (title) {
    return (
      <>
        <div className="DetailsBar__title">{title}</div>
        <BarButton
          className="pl2 pr2"
          tooltip="Close Post"
          onClick={() => {
            closePost({ transition: !isWideViewport });
            window.history.pushState(null, '', '#');
          }}
        >
          <LineIcon
            className="DetailsBar__close-icon"
            type="cross"
            thickness={3}
          />
        </BarButton>
      </>
    );
  } else {
    return null;
  }
};

export default DetailsBar;
