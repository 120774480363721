import Markdown from 'markdown-it';

const md = new Markdown({ linkify: true });

const convert = markdown => md.render(markdown);

const parseComments = (data, postAuthor) =>
  data.comments.map(comment => parseComment(comment, postAuthor));

const parseComment = (comment, postAuthor) => {
  const { body, author, created_utc, replies, id, likes } = comment;
  const isOP = author.name === postAuthor;
  const parsedBody = convert(body).replace(
    /<a href=/g,
    '<a target="_blank" rel="noopener noreferrer" href='
  );
  const parsedReplies =
    replies && replies.length > 0
      ? replies.map(reply => {
          return parseComment(reply, postAuthor);
        })
      : [];
  return {
    body: parsedBody,
    replies: parsedReplies,
    timeAgo: created_utc,
    byOP: isOP,
    author: author.name,
    vote: parseVote(likes),
    id,
  };
};

const parseSelftext = selftext =>
  convert(selftext).replace(
    /<a href=/g,
    '<a target="_blank" rel="noopener noreferrer" href='
  );

export { parseComments, parseSelftext };

function parseVote(likes) {
  if (likes === null) return 0;
  return likes ? 1 : -1;
}
