import React from 'react';
import LineIcon from '../Icons/LineIcon';
import { cc } from '../../modules/styles';
import './MenuSubheader.scss';

/**
 * Header of a sub-section in the Menu
 */

const MenuSubheader = ({ name, onActionRequest, className }) => {
  return (
    <div
      className={cc(
        'MenuSubheader ui-text-color-1',
        'flex flex-y flex-between',
        'pl3 pt3 pb3',
        'uppercase',
        className
      )}
    >
      <div className="MenuSubheader__text">{name}</div>
      {!!onActionRequest && (
        <button
          className="MenuSubheader__button simple-button block ui-text-accent"
          onClick={onActionRequest}
        >
          <LineIcon type="plus" width={14} thickness={3} />
        </button>
      )}
    </div>
  );
};

export default MenuSubheader;
