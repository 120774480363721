export const nodes = {
  root: document.documentElement,
  appRoot: document.getElementById('app-root'),
  menuRoot: document.getElementById('menu-root'),
  postsRoot: document.getElementById('posts-view-root'),
  menuBarRoot: document.getElementById('footer-menu-root'),
  postsBarRoot: document.getElementById('posts-view-bar-root'),
  detailsRoot: document.getElementById('post-detail-root'),
  detailsBarRoot: document.getElementById('details-bar-root'),
  modalRoot: document.getElementById('modal-root'),
  messageRoot: document.getElementById('message-root'),
};

const postsView = nodes.postsRoot.parentNode;
const detailsView = nodes.detailsRoot.parentNode;
const menuView = nodes.menuRoot.parentNode;

// GROUPS
const menuNodes = [nodes.menuBarRoot, menuView];
const postsNodes = [postsView, nodes.postsBarRoot];
const detailsNodes = [detailsView, nodes.detailsBarRoot];

const CLASSES = {
  openedMenu: 'opened-menu',
  openedDetails: 'opened-details',
  transitionedView: 'View--transitioned',
};

let menuExpanded = false;

export const Actions = {
  expandMenu(expand) {
    if (expand) {
      addClass([...menuNodes, ...postsNodes], [CLASSES.openedMenu]);
      addClass(postsNodes, [CLASSES.transitionedView]);
    } else {
      postsView.ontransitionend = () => {
        removeClass(postsNodes, [CLASSES.transitionedView]);
        postsView.ontransitionend = () => null;
      };

      removeClass([...menuNodes, ...postsNodes], [CLASSES.openedMenu]);
    }
  },

  showDetailsView(afterTransition, withTransition = false) {
    function showView() {
      addClass([...detailsNodes, ...postsNodes], [CLASSES.openedDetails]);
    }

    if (withTransition) {
      detailsView.ontransitionend = () => {
        removeClass(
          [...detailsNodes, ...postsNodes],
          [CLASSES.transitionedView]
        );

        if (afterTransition) {
          afterTransition();
        }
        detailsView.ontransitionend = () => null;
      };

      addClass([...detailsNodes, ...postsNodes], [CLASSES.transitionedView]);
      showView();
    } else {
      if (afterTransition) {
        afterTransition();
      }
      showView();
    }
  },

  showPostsView(afterTransition, withTransition = false) {
    function showView() {
      removeClass([...detailsNodes, ...postsNodes], [CLASSES.openedDetails]);
    }

    if (withTransition) {
      postsView.ontransitionend = () => {
        removeClass(
          [...detailsNodes, ...postsNodes],
          [CLASSES.transitionedView]
        );
        if (afterTransition) {
          afterTransition();
        }
        postsView.ontransitionend = () => null;
      };

      addClass([...detailsNodes, ...postsNodes], [CLASSES.transitionedView]);
      showView();
    } else {
      if (afterTransition) {
        afterTransition();
      }
      showView();
    }
  },

  scrollToTopOfDetailView() {
    nodes.detailsRoot.scrollTo(0, 0);
  },

  toggleMenu(newExpanded) {
    if (newExpanded === undefined || newExpanded === null) {
      newExpanded = !menuExpanded;
    }
    Actions.expandMenu(newExpanded);
    menuExpanded = newExpanded;
  },

  setBarsPosition(position) {
    nodes.root.dataset.barsPosition = position;
  },
};

// function handleFocusWithKeyAnimationEnd(ev) {
//   ev.target.classList.remove('with-key');
// }
//
// nodes.menuRoot.addEventListener('animationend', handleFocusWithKeyAnimationEnd);
// nodes.postsRoot.addEventListener(
//   'animationend',
//   handleFocusWithKeyAnimationEnd
// );
// nodes.detailsRoot.addEventListener(
//   'animationend',
//   handleFocusWithKeyAnimationEnd
// );

function removeClass(nodes, classes) {
  nodes.forEach(node => {
    node.classList.remove(...classes);
  });
}

function addClass(nodes, classes) {
  nodes.forEach(node => {
    node.classList.add(...classes);
  });
}
