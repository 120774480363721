export const SUBTYPE = {
  SUBREDDIT: 'subreddit',
  CHANNEL: 'channel',
  SAVED_POSTS: 'savedposts',
};

export const POSTS_LIMIT = 50;

export const BREAKPOINTS = {
  LARGE_X: '460px',
};
