import React from 'react';
import { cc } from '../../modules/styles';

import './BarButton.scss';

/**
 * Button for the bottom bar
 */

function BarButton({ children, className, onClick, tooltip, href }) {
  const Element = href ? 'a' : 'button';
  return (
    <Element
      className={cc('BarButton reset-button', className)}
      onClick={onClick}
      title={tooltip}
      href={href ? href : null}
    >
      {children}
    </Element>
  );
}

export default BarButton;
