import React from 'react';
import TimeSince from './TimeSince';

/**
 * Parses a Data to show how much has passed between now and then
 */

const TimeAgo = ({ timestamp, short = false, className }) => {
  const timeSince = TimeSince(timestamp, short);

  return (
    <time dateTime={timestamp} className={className}>
      {timeSince}
    </time>
  );
};

// TimeAgo.propTypes = {
//   timestamp: React.PropTypes.number.isRequired,
// };

export default TimeAgo;
