import React from 'react';
import PropTypes from 'prop-types';
import SubredditSearcher from '../SubredditSearcher/SubredditSearcher';
import '../ModalForm/ModalForm.scss';

class SubredditAdder extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    return (
      <div
        className="ModalForm"
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          return;
        }}
      >
        {this.props.title && (
          <label className="mb3 block" htmlFor="sub-searcher">
            {this.props.title}
          </label>
        )}
        <SubredditSearcher reddit={this.props.reddit}>
          {({ text, onQueryUpdate, results }) => (
            <>
              <input
                type="text"
                onChange={onQueryUpdate}
                placeholder="Subreddit name"
                value={text}
                className="ModalForm__input"
                ref={this.inputRef}
                onKeyPress={stopKeyPressPropagation}
                id="sub-searcher"
              />
              {results.length > 0 && (
                <div className="ModalForm__results-list anim-reveal-down mt4">
                  {results.map(result => (
                    <button
                      className="ModalForm__result-item mr1 mb1"
                      key={result}
                      onClick={() => {
                        this.props.onComplete(result);
                      }}
                    >
                      {result}
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </SubredditSearcher>
      </div>
    );
  }
}

export default SubredditAdder;

SubredditAdder.propTypes = {
  title: PropTypes.string,
  reddit: PropTypes.object.isRequired,
  /** Callback for when a subreddit has been selected */
  onComplete: PropTypes.func,
};

function stopKeyPressPropagation(ev) {
  ev.stopPropagation();
}
