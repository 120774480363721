const ActionStore = (function() {
  const topics = {};
  const hOP = topics.hasOwnProperty;

  return {
    on: function(topic, listener) {
      // Create the topic's object if not yet created
      if (!hOP.call(topics, topic)) topics[topic] = [];

      // Add the listener to queue
      topics[topic].push(listener);
    },
    dispatch: function(topic, info) {
      // If the topic doesn't exist, or there's no listeners in queue, just leave
      if (!hOP.call(topics, topic)) return;

      // Cycle through topics queue, fire!
      topics[topic].forEach(function(item) {
        item(info != undefined ? info : null);
      });
    },
  };
})();

export default ActionStore;
