import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/**
 * Renders any react component in the given DOM node
 */

const Portal = ({ children, mountNode }) => {
  return ReactDOM.createPortal(children, mountNode);
};

export default Portal;
