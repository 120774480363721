import ActionStore from './modules/ActionStore';

const { dispatch } = ActionStore;

export function openPost(data) {
  dispatch('open-post', data);
}

export function closePost(data = { transition: false }) {
  dispatch('close-post', data);
}

export function savePost(postID) {
  dispatch('save-post', postID);
}

export function unsavePost(postId) {
  dispatch('unsave-post', postId);
}

export function loadAll() {
  ActionStore.dispatch('load-all');
}

export function switchSubscription(newSelection) {
  ActionStore.dispatch('switch-subscription', newSelection);
}

export function openSubreddit(name) {
  ActionStore.dispatch('open-subreddit', name);
}

export function openImageModal(imageURL) {
  ActionStore.dispatch('open-image-modal', imageURL);
}

export function closeModal() {
  ActionStore.dispatch('close-modal');
}

export function signOut() {
  ActionStore.dispatch('sign-out');
}

export function showMessage(text, type = 'error', duration = 5000) {
  ActionStore.dispatch('show-message', { text, duration });
}
