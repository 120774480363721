import React, { useRef } from 'react';
import LineIcon from '../Icons/LineIcon';

import './ImageGallery.scss';
import { onMount } from '../../hooks/onMount';

export function ImageGallery({ images = [], onCloseRequest }) {
  const galleryRef = useRef(null);

  onMount(() => {
    galleryRef?.current?.focus();
  });

  return (
    <div
      className="ImageGallery"
      onClick={ev => {
        ev.preventDefault();
        ev.stopPropagation();
        return;
      }}
      tabIndex={0}
      ref={galleryRef}
    >
      {images.map(image => (
        <img
          loading="lazy"
          key={image.url}
          src={image.url}
          width={image.w}
          height={image.h}
        />
      ))}

      <button
        className="ImageGallery__close reset-button"
        onClick={onCloseRequest}
        title="Close Gallery"
      >
        <LineIcon type="cross" width={20} height={20} thickness={2} />
      </button>
    </div>
  );
}
