import React from 'react';
import PropTypes from 'prop-types';
import { closeModal } from '../../actions';
import Modal from './Modal';

/**
 * Modal for Images
 */

function ImageModal({ imageURL }) {
  return (
    <Modal onCloseRequest={closeModal} forImage>
      <img className="Modal__image" src={imageURL} onClick={closeModal} />
    </Modal>
  );
}

ImageModal.propTypes = {
  imageURL: PropTypes.string.isRequired,
};

export default ImageModal;
