import { useState, useEffect } from 'react';

export function useMatchMedia(mediaQuery) {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(mediaQuery);
    const checker = () => setActive(media.matches);

    checker(); // check the 1st time...
    media.addEventListener('change', checker); // ...and when the media query changes

    return () => {
      media.removeEventListener('change', checker);
    };
  }, [mediaQuery]);

  return isActive;
}
