const parseThumbnail = thumbnail => {
  return ['self', 'nsfw', 'default', 'image', 'spoiler'].indexOf(thumbnail) > -1
    ? null
    : thumbnail.replace('http://', '//');
};

export function parsePost(data) {
  // Extract fields with same name
  const {
    title,
    domain,
    subreddit,
    stickied,
    thumbnail,
    url,
    author,
    created_utc,
    num_comments,
    permalink,
    id,
    selftext,
    link_flair_text,
    saved,
    likes,
    media,
  } = data;

  const galleryImageItems = getGalleryData(data);

  return {
    // same name
    title: title.replace(/&amp;/g, '&'),
    domain,
    stickied,
    id,
    url,
    created_utc,
    num_comments,
    permalink,
    selftext,
    saved,
    author: author.name,
    subreddit: subreddit.display_name,
    thumbnail: parseThumbnail(thumbnail),
    likes,
    media,
    // different name
    isSelf: data.is_self,
    nsfw: data.over_18,
    label: link_flair_text
      ? link_flair_text.replace('[', '').replace(']', '')
      : null,
    previewUrl:
      data.preview && data.preview.images && data.preview.images.length > 0
        ? data.preview.images[0].source.url
        : null,
    previewImage:
      getPreviewImage(data.preview) || getRedditImagePostPreview(data),
    galleryItemsAmount: galleryImageItems ? galleryImageItems.length : null,
    galleryImages: galleryImageItems,
  };
}

export function parsePosts(data) {
  return data.filter(post => post.title != null).map(parsePost);
}

export function appendPosts(basePosts, newPosts) {
  const basePostsIds = basePosts.map(post => post.id);
  const newPostsLength = newPosts.length;
  for (let i = 0; i < newPostsLength; i++) {
    if (basePostsIds.includes(newPosts[i].id)) continue;
    basePosts.push(newPosts[i]);
  }
  return basePosts;
}

function getPreviewImage(preview) {
  if (
    !preview ||
    !preview.images ||
    preview.images.length === 0 ||
    preview.images[0].resolutions.length < 3
  ) {
    return null;
  }

  if (isRetina() && preview.images[0].resolutions.length > 3) {
    return preview.images[0].resolutions[3];
  }

  return preview.images[0].resolutions[2];
}

function getRedditImagePostPreview(data) {
  const { domain, media_metadata } = data;
  if (domain !== 'reddit.com' || !media_metadata) {
    return null;
  }

  const firstImageObject = Object.keys(media_metadata)
    .map(key => media_metadata[key])
    .filter(media => media.e === 'Image')[0];
  if (!firstImageObject) {
    return null;
  }

  if (isRetina() && firstImageObject.p.length > 3) {
    return convert2Preview(firstImageObject.p[3]);
  }

  return convert2Preview(firstImageObject.p[2]);
}

function convert2Preview(imgObj) {
  if (imgObj) {
    return {
      width: imgObj.x,
      height: imgObj.y,
      url: imgObj.u,
    };
  } else {
    null;
  }
}

function isRetina() {
  return window.devicePixelRatio >= 2;
}

function getGalleryData(data) {
  const { is_gallery, media_metadata } = data;
  if (!is_gallery || !media_metadata) return null;

  const items = Object.keys(media_metadata).map(id => {
    const image = media_metadata[id];
    const biggestImage = image.p[image.p.length - 1];

    return {
      w: biggestImage.x,
      h: biggestImage.y,
      url: biggestImage.u,
    };
  });

  return items;
}
