export default function(epoch, short = false) {
  let secs = new Date().getTime() / 1000 - epoch;
  Math.floor(secs);
  let minutes = secs / 60;
  secs = Math.floor(secs % 60);
  if (minutes < 1) {
    return short ? `${secs}s` : secs + (secs > 1 ? ' seconds' : ' second');
  }
  let hours = minutes / 60;
  minutes = Math.floor(minutes % 60);
  if (hours < 1) {
    return short
      ? `${minutes}m`
      : minutes + (minutes > 1 ? ' minutes' : ' minute');
  }
  let days = hours / 24;
  hours = Math.floor(hours % 24);
  if (days < 1) {
    return short ? `${hours}h` : hours + (hours > 1 ? ' hours' : ' hour');
  }
  let weeks = days / 7;
  days = Math.floor(days % 7);
  if (weeks < 1) {
    return short ? `${days}d` : days + (days > 1 ? ' days' : ' day');
  }
  let months = weeks / 4.35;
  weeks = Math.floor(weeks % 4.35);
  if (months < 1) {
    return short ? `${weeks}w` : weeks + (weeks > 1 ? ' weeks' : ' week');
  }
  let years = months / 12;
  months = Math.floor(months % 12);
  if (years < 1) {
    return short ? `${months}mo` : months + (months > 1 ? ' months' : ' month');
  }
  years = Math.floor(years);
  return short ? `${years}y` : years + (years > 1 ? ' years' : ' year');
}
