import React from 'react';
import PropTypes from 'prop-types';
import { cc } from '../../modules/styles';
import './Icon.scss';

/**
 * Icon component
 */

function Icon({ name, size = '1rem', className, rotation, style }) {
  return (
    <svg
      className={cc('Icon', className)}
      style={{
        '--size': size ? size : null,
        transform: rotation ? `rotate(${rotation}deg)` : null,
        ...style,
      }}
    >
      <use xlinkHref={`/icons.svg#icon-${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Degrees to rotate the icon towards */
  rotation: PropTypes.number,
};

export default Icon;
