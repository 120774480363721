import React from 'react';
import ActionStore from '../../modules/ActionStore';
import {
  accentColorsDark,
  accentColorsLight,
  blackDarkBgs,
  getNextTheme,
} from '../../modules/ThemeSwitcher';
import { setPreference } from '../../modules/Preferences';
import { cc } from '../../modules/styles';

class PreferencesMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { expandedReplies, bigThumbnails, preferences } = this.props;

    const {
      theme = 'auto',
      barsPosition = 'bottom',
      blurNSFW = true,
      colorL = 0,
      colorD = 0,
      blackDarkBg = 0,
    } = preferences;

    return (
      <div style={{ margin: '1rem' }}>
        <Group heading="Appearance" className="mt0">
          <Item
            onClick={() => {
              setPreference('theme', getNextTheme());
            }}
          >
            Theme: <b className="capitalize">{theme}</b>
          </Item>
          <Item
            onClick={() => {
              setPreference('colorL', getNextItem(colorL, accentColorsLight));
            }}
          >
            Light Theme Accent{' '}
            <div
              style={{
                marginLeft: '.25rem',
                display: 'inline-block',
                width: colorIndicatorSize,
                height: colorIndicatorSize,
                borderRadius: '50%',
                backgroundColor: 'var(--accent-color-l)',
              }}
            />{' '}
            <b>{accentColorsLight[colorL]}</b>
          </Item>
          <Item
            onClick={() => {
              setPreference('colorD', getNextItem(colorD, accentColorsDark));
            }}
          >
            Dark Theme Accent{' '}
            <div
              style={{
                marginLeft: '.25rem',
                display: 'inline-block',
                width: colorIndicatorSize,
                height: colorIndicatorSize,
                borderRadius: '50%',
                backgroundColor: 'var(--accent-color-d)',
              }}
            />{' '}
            <b>{accentColorsDark[colorD]}</b>
          </Item>
          <Item
            onClick={() => {
              setPreference(
                'blackDarkBg',
                getNextItem(blackDarkBg, blackDarkBgs)
              );
            }}
          >
            True Black Dark Theme:{' '}
            <b className="capitalize">
              {blackDarkBgs[blackDarkBg] === 'y' ? 'Yes' : 'No'}
            </b>
          </Item>
        </Group>

        <Group heading="Layout" className="mt0">
          <Item
            onClick={() => {
              ActionStore.dispatch('toggle-big-thumbnails');
            }}
          >
            Post Thumbnails: <b>{bigThumbnails ? 'Big' : 'Small'}</b>
          </Item>

          <Item
            onClick={() => {
              let newPosition;
              if (barsPosition === 'top') {
                newPosition = 'bottom';
              } else if (barsPosition === 'bottom') {
                newPosition = 'top';
              }
              setPreference('barsPosition', newPosition);
            }}
          >
            Bar Position: <b className="capitalize">{barsPosition}</b>
          </Item>
        </Group>

        <Group heading="Behavior" className="mt0">
          <Item
            onClick={() => {
              setPreference('blurNSFW', !blurNSFW);
            }}
          >
            NSFW Thumbnails: <b>{blurNSFW ? 'Blurred' : 'Regular'}</b>
          </Item>
          <Item
            onClick={() => {
              ActionStore.dispatch('toggle-expanded-replies');
            }}
          >
            Replies: <b>{expandedReplies ? 'Expanded' : 'Collapsed'}</b>
          </Item>
        </Group>
      </div>
    );
  }
}

export default PreferencesMenu;

const colorIndicatorSize = '.75rem';

function Group({ heading, children, className = '' }) {
  return (
    <div className={className} style={{ marginBlockEnd: '2rem' }}>
      <h2
        className="uppercase ui-text-color-2 mt0"
        style={{ fontSize: '1rem', letterSpacing: '.5px' }}
      >
        {heading}
      </h2>
      {children}
    </div>
  );
}

function Item({ onClick, children, className = '' }) {
  return (
    <button
      className={cc('block reset-button mb4', className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function getNextItem(currentIndex, list) {
  const totalIndex = list.length - 1;
  if (currentIndex === totalIndex) {
    return 0;
  } else {
    return currentIndex + 1;
  }
}
