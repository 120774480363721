import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { cc } from '../../modules/styles';

import './Modal.scss';

function Modal({
  children,
  onCloseRequest,
  forImage = false,
  forGallery = false,
}) {
  useEffect(() => {
    const onEsc = ev => {
      if (['esc', 'escape'].includes(ev.key.toLowerCase())) {
        onCloseRequest();
      }
    };

    window.addEventListener('keydown', onEsc);

    return () => {
      window.removeEventListener('keydown', onEsc);
    };
  }, []);

  return (
    <div className="Modal" onClick={onCloseRequest}>
      <div className="Modal__overlay" />
      <div
        className={cc(
          'Modal__content z-base anim-reveal-grow',
          forImage ? 'Modal__content--image' : '',
          forGallery ? 'Modal__content--gallery' : ''
        )}
      >
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseRequest: PropTypes.func.isRequired,
};

export default Modal;
