import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icons/Icon';
import { SUBTYPE } from '../../constants';
import ActionStore from '../../modules/ActionStore';
import BarButton from './BarButton';
import LineIcon from '../Icons/LineIcon';
import CheckIcon from '../Icons/CheckIcon';
import { showMessage } from '../../actions';

import './PostsBar.scss';

function PostsBar({ name, type, isSubscribed, reddit, selected = 'posts' }) {
  const isSubreddit = type === SUBTYPE.SUBREDDIT;
  const shouldShowButton =
    isSubreddit && ['frontpage', 'all'].indexOf(name.toLowerCase()) < 0;

  const postsSelected = selected === 'posts';
  const prefsSelected = selected === 'prefs';

  return (
    <>
      <div className="PostsBar__side">
        <BarButton
          className="PostsBar__menu-button PostsBar__main-button flex flex-y flex-x"
          onClick={() => {
            ActionStore.dispatch('toggle-menu');
          }}
        >
          <Icon name="menu" size="1.4rem" />
          <p className="visually-hide">Menu</p>
        </BarButton>
      </div>

      <div className="PostsBar__center flex flex-y flex-x">
        <BarButton
          onClick={() => {
            if (postsSelected) {
              ActionStore.dispatch('reload-posts');
            } else {
              ActionStore.dispatch('show-main-view', 'posts');
            }
          }}
          className={`PostsBar__text ${postsSelected ? 'ui-text-accent' : ''}`}
          title={
            shouldShowButton
              ? isSubscribed
                ? 'Subscribed'
                : 'Not subscribed'
              : null
          }
        >
          {name}
        </BarButton>

        {/* SUBSCRIBE BUTTON */}
        {shouldShowButton && (
          <BarButton
            className="PostsBar__subscribe-button"
            tooltip={isSubscribed ? 'Unsubscribe' : 'Subscribe'}
            onClick={() => {
              if (isSubscribed) {
                unsubscribe(name, reddit);
              } else {
                subscribe(name, reddit);
              }
            }}
          >
            {isSubscribed ? (
              <CheckIcon width="18" />
            ) : (
              <LineIcon type="plus" thickness={3} width={12} />
            )}
          </BarButton>
        )}
      </div>

      <div className="PostsBar__side flex">
        <BarButton
          onClick={() => {
            ActionStore.dispatch('show-main-view', 'prefs');
          }}
          className="PostsBar__main-button flex flex-y flex-x push-right"
        >
          <Icon
            name="settings"
            className={`${prefsSelected ? 'ui-text-accent' : ''}`}
          />
          <p className="visually-hide">Preferences</p>
        </BarButton>
      </div>
    </>
  );
}

export default React.memo(PostsBar);

PostsBar.protoTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  expandedReplies: PropTypes.bool,
  reddit: PropTypes.object.isRequired,
};

function subscribe(name, reddit) {
  reddit
    .getSubreddit(name)
    .subscribe()
    .then(() => {
      ActionStore.dispatch('subscription', name);
    })
    .catch(error => {
      showMessage(`Error subscribing to subreddit: ${error}`);
    });
}

function unsubscribe(name, reddit) {
  reddit
    .getSubreddit(name)
    .unsubscribe()
    .then(() => {
      ActionStore.dispatch('unsubscription', name);
    })
    .catch(error => {
      showMessage(`Error unsubscribing to subreddit: ${error}`);
    });
}
