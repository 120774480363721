import React from 'react';
import PropTypes from 'prop-types';

/**
 * Pin Icon, used for stickied posts
 */

const PinIcon = ({ size = 24, className }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24">
      <path d="M16.729 4.271c-0.389-0.391-1.021-0.393-1.414-0.004-0.104 0.104-0.176 0.227-0.225 0.355-0.832 1.736-1.748 2.715-2.904 3.293-1.297 0.64-2.786 1.085-5.186 1.085-0.13 0-0.26 0.025-0.382 0.076-0.245 0.102-0.439 0.297-0.541 0.541-0.101 0.244-0.101 0.52 0 0.764 0.051 0.123 0.124 0.234 0.217 0.326l3.243 3.243-4.537 6.050 6.050-4.537 3.242 3.242c0.092 0.094 0.203 0.166 0.326 0.217 0.122 0.051 0.252 0.078 0.382 0.078s0.26-0.027 0.382-0.078c0.245-0.102 0.44-0.295 0.541-0.541 0.051-0.121 0.077-0.252 0.077-0.381 0-2.4 0.444-3.889 1.083-5.166 0.577-1.156 1.556-2.072 3.293-2.904 0.129-0.049 0.251-0.121 0.354-0.225 0.389-0.393 0.387-1.025-0.004-1.414l-3.997-4.020z" />
    </svg>
  );
};

PinIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PinIcon;
