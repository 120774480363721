import React, { useEffect } from 'react';

import './Message.scss';

export function Message({
  text,
  type = 'error',
  onClosed,
  closeTimeout = 5000,
}) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClosed();
    }, closeTimeout);

    return () => {
      clearTimeout(timer);
    };
  }, [closeTimeout]);

  return (
    <div
      className="Message flex-y w100 flex anim-reveal-down borders-thin round-corners"
      style={{
        backgroundColor: 'hsla(var(--accent), .3)',
        borderColor: 'var(--accent-color)',
      }}
    >
      <p className="ui-text-accent mt0 mb0">{text}</p>
      <button
        className="Message__close-button push-right ui-text-accent reset-button"
        onClick={onClosed}
      >
        &times;
      </button>
    </div>
  );
}
