import React from 'react';
import PropTypes from 'prop-types';
import { POSTS_LIMIT, SUBTYPE } from '../../constants';
import ActionStore from '../../modules/ActionStore';
import Loading from '../Loading/Loading';
import PostItem from './PostItem';
import MainButton from '../Button/MainButton';
import { closePost, openPost } from '../../actions';
import { parsePosts, parsePost, appendPosts } from './PostListHelpers';
import './PostList.scss';

/**
 * List of Posts from a subreddit or a channel
 */

class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      posts: [],
    };
    this.currentListing = null;
    reddit = this.reddit = props.reddit;

    this.loadMore = this.loadMore.bind(this);
  }

  requestPosts({ subscription, loadMore = false }) {
    this.setState({
      posts: loadMore ? this.state.posts : [],
      loading: true,
    });

    if (loadMore) {
      this.currentListing
        .fetchMore({ amount: POSTS_LIMIT, append: false })
        .then(data => {
          this.renderPosts({ posts: data, isLoadingMore: true });
        });
      return;
    }

    switch (subscription.type) {
      case SUBTYPE.SUBREDDIT:
        if (subscription.name.toLowerCase() === 'frontpage') {
          this.reddit.getHot().then(posts => {
            this.renderPosts({ posts: posts });
          });
        } else {
          this.reddit
            .getHot(subscription.name)
            .then(posts => {
              this.renderPosts({ posts: posts });
            })
            .catch(() => {
              this.setState({ loading: false });
              alert(
                `Oh no, '${subscription.name}' could not be loaded! Try another subreddit.`
              );
            });
        }
        break;
      case SUBTYPE.CHANNEL:
        this.reddit
          .oauthRequest({ uri: `/me/m/${subscription.name}/` })
          .then(posts => {
            this.renderPosts({ posts: posts });
          });
        break;
      case SUBTYPE.SAVED_POSTS:
        this.reddit
          .getMe()
          .then(me => me.getSavedContent())
          .then(savedContent => {
            this.renderPosts({ posts: savedContent });
          });
    }
  }

  renderPosts({ posts, isLoadingMore = false }) {
    const parsedPostsData = parsePosts(posts);
    this.currentListing = posts;
    this.setState(prevState => ({
      posts: isLoadingMore
        ? appendPosts(prevState.posts, parsedPostsData)
        : parsedPostsData,
      loading: false,
    }));
  }

  loadMore() {
    this.requestPosts({
      subscription: this.props.subscription,
      loadMore: true,
    });
  }

  fetchPost(id, transition = false) {
    getPostById(id, this.state.posts).then(post => {
      openPost({ post, transition });
    });
  }

  checkForLocationHash(hash) {
    const match = hash.match(/(#post:)((?:[a-zA-Z0-9]*))/);
    if (match && match[2]) {
      const postId = match[2];
      this.fetchPost(postId);
    }
  }

  componentDidMount() {
    this.requestPosts({ subscription: this.props.subscription });

    // When loading the app the first time, we check if a post should be opened.
    this.checkForLocationHash(location.hash);

    // Handle history changes (back/forward buttons or swipes)
    window.addEventListener('popstate', event => {
      const hash = window.location.hash;

      // Trigger the view changes immediately - without transitions.
      if (!hash) {
        closePost();
      } else {
        this.checkForLocationHash(hash);
      }
    });

    ActionStore.on('PostList:fetch-post', id => {
      this.fetchPost(id, true);
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.subscription.type !== this.props.subscription.type ||
      prevProps.subscription.name !== this.props.subscription.name
    ) {
      const { subscription } = this.props;
      this.requestPosts({ subscription, loadMore: false });
    }
  }

  render() {
    const {
      currentPost,
      bigThumbnails,
      blurNSFW = true,
      showing = false,
    } = this.props;
    const { posts, loading } = this.state;

    if (!showing) return null;

    const empty = posts.length === 0;
    if (loading && empty) {
      return (
        <div className="pt4 pb4">
          <Loading className="push-center" />
        </div>
      );
    } else {
      return (
        <div className="PostList anim-reveal-down">
          {posts.map(post => {
            const {
              title,
              subreddit,
              domain,
              isSelf,
              id,
              thumbnail,
              nsfw,
              stickied,
              label,
              saved,
              likes,
              previewUrl,
              previewImage,
              galleryItemsAmount,
            } = currentPost.id === post.id ? currentPost : post;
            const isSelected = id === currentPost.id;
            return (
              <PostItem
                key={id}
                id={id}
                title={title}
                subreddit={subreddit}
                domain={domain}
                isSelf={isSelf}
                thumbnail={thumbnail}
                nsfw={nsfw}
                stickied={stickied}
                isSelected={isSelected}
                label={label}
                saved={saved}
                likes={likes}
                previewImage={previewImage}
                bigThumbnail={bigThumbnails}
                blurNSFW={blurNSFW}
                previewUrl={previewUrl}
                openPostRequest={() => {
                  openPost(getPostById(id, posts));
                }}
                galleryItemsAmount={galleryItemsAmount}
              />
            );
          })}
          {posts.length > 0 && (
            <div className="pt3 pb3">
              <MainButton
                className="w50 block push-center mt3 mb3"
                onClick={this.loadMore}
                processing={loading}
              >
                {loading ? 'Loading...' : 'Load More'}
              </MainButton>
            </div>
          )}
        </div>
      );
    }
  }
}

PostList.propTypes = {
  subscription: PropTypes.shape({
    type: PropTypes.oneOf([
      SUBTYPE.SUBREDDIT,
      SUBTYPE.CHANNEL,
      SUBTYPE.SAVED_POSTS,
    ]).isRequired,
    name: PropTypes.string,
  }).isRequired,
  currentPost: PropTypes.object,
  reddit: PropTypes.object.isRequired,
  bigThumbnails: PropTypes.bool,
};

export default PostList;

let reddit;
const postsCache = new Map();

// HELPERS

function getPostById(postId, posts) {
  const post = posts.find(post => post.id === postId);
  if (postsCache.has(postId)) {
    return Promise.resolve(postsCache.get(postId));
  }
  if (post) {
    return Promise.resolve(post);
  }

  return reddit
    .getSubmission(postId)
    .fetch()
    .then(data => {
      const parsedPost = parsePost(data);
      postsCache.set(postId, parsedPost);
      return parsedPost;
    })
    .catch(error => {
      throw error;
    });
}
