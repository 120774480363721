import React from 'react';
import PropTypes from 'prop-types';
import Label from '../Label/Label';
import Loading from '../Loading/Loading';
import TimeAgo from '../TimeAgo/TimeAgo';
import PostComment from '../PostComment/PostComment';
import PinIcon from '../Icons/PinIcon';
import { parseComments, parseSelftext } from './PostDetailHelpers';
import EmptyPostDetail from './EmptyPostDetail';
import Image from '../Image/Image';
import { cc } from '../../modules/styles';
import { openImageModal, showMessage } from '../../actions';
import SaveButton from './SaveButton';
import Icon from '../Icons/Icon';
import ActionStore from '../../modules/ActionStore';

import './PostDetail.scss';

/**
 * Shows the detailed view of a post and its comments
 */

class PostDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      loading: false,
      currentPostId: null,
      imageLoaded: false,
    };
    this.reddit = props.reddit;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.post !== this.props.post &&
      this.props.post.id &&
      this.props.post.id !== prevProps.post.id
    ) {
      const { post } = this.props;

      this.setState({ loading: true, comments: [], imageLoaded: false });

      const postId = post.id;

      this.reddit
        .oauthRequest({ uri: `/comments/${postId}/` })
        .then(data => {
          const parsedComments = parseComments(data, post.author);
          this.setState({
            comments: parsedComments,
            loading: false,
            currentPostId: postId,
          });
        })
        .catch(err => {
          this.setState({ loading: false });
          showMessage(`Error Getting Comments Data: ${err}`);
          console.error('Error Getting Comments Data', err);
        });
    }
  }

  render() {
    const { post } = this.props;
    const { comments, loading } = this.state;

    if (!post || !post.id) {
      return <EmptyPostDetail />;
    }

    const {
      url,
      isSelf,
      title,
      domain,
      subreddit,
      created_utc,
      num_comments,
      author,
      permalink,
      selftext,
      stickied,
      nsfw,
      label,
      saved,
      likes,
      media,
      previewUrl,
      previewImage,
      galleryImages = null,
    } = post;

    const parsedSelftext = parseSelftext(selftext);
    const isGallery = galleryImages !== null && galleryImages.length > 1;
    const galleryItemsAmount = isGallery ? galleryImages.length : 0;

    // Links previews detection
    const videoData = getRedditVideoData(media);
    const vredditURL = videoData ? videoData.url : null;
    const youtubeID = !vredditURL ? getYouTubeVideoIDfromURL(url) : null;
    const gfycatID = !youtubeID ? getGfycatVideoIDfromURL(url) : null;
    const imgurVideo = !gfycatID ? getImgurGifVideo(url) : null;
    const imageUrl =
      !imgurVideo && previewUrl ? previewUrl : checkImageLink(url);

    const showImagePreview =
      !vredditURL &&
      !youtubeID &&
      !gfycatID &&
      !imgurVideo &&
      (imageUrl || previewImage);

    const voted = likes !== null;
    const liked = voted && likes;

    return (
      <div className="PostDetail">
        <div className="PostDetail__header pt3 pl3 pr3 pb2">
          <a
            className="PostDetail__title ui-text-color-1 no-underline inline"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {stickied && (
              <PinIcon className="PostDetail__pin-icon" size="1em" />
            )}
            {title}
          </a>

          <div className="flex mb1">
            <div>
              {/* TODO: turn to text - with aria-hidden 'domain' label */}
              <a
                className="block ui-text-accent no-underline"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {domain}
              </a>
              <div className="ui-text-color-2">by {author}</div>
            </div>

            <div className="PostDetail__buttons flex flex-y push-right">
              <button
                className="PostDetail__vote-button simple-button"
                onClick={() => {
                  ActionStore.dispatch('vote-post', {
                    postId: post.id,
                    dir: voted && liked ? 0 : 1,
                  });
                }}
                title={voted && liked ? 'Remove upvote' : 'Upvote post'}
              >
                <Icon
                  name="triangle"
                  size="1.125rem"
                  className="PostDetail__vote-icon ui-text-accent"
                  style={{
                    strokeWidth: 1.5,
                    fill: voted && liked ? 'currentColor' : 'transparent',
                  }}
                />
              </button>
              <button
                className="PostDetail__vote-button simple-button ml1"
                style={{ position: 'relative', top: -2 }}
                onClick={() => {
                  ActionStore.dispatch('vote-post', {
                    postId: post.id,
                    dir: voted && !liked ? 0 : -1,
                  });
                }}
                title={voted && !liked ? 'Remove downvote' : 'Downvote post'}
              >
                <Icon
                  name="triangle"
                  size="1.125rem"
                  rotation={180}
                  className="PostDetail__vote-icon ui-text-accent"
                  style={{
                    strokeWidth: 1.5,
                    fill: voted && !liked ? 'currentColor' : 'transparent',
                  }}
                />
              </button>
              <SaveButton
                postId={post.id}
                saved={saved}
                className="pl0 pr0 ml1 ui-text-accent"
              />
              {window.navigator.share && (
                <button
                  className="pl0 pr0 ml1 simple-button"
                  onClick={() => {
                    shareUrl(isSelf ? window.location.href : url, title);
                  }}
                  title="Share this post"
                >
                  <Icon name="share" size="24px" className="ui-text-accent" />
                </button>
              )}
            </div>
          </div>

          {(label || nsfw) && (
            <div className="flex">
              {label && <Label text={label} type="basic" className="mr2" />}
              {nsfw && <Label text="nsfw" type="warning" />}
            </div>
          )}
        </div>

        <div
          className={cc(
            'PostDetail__header',
            'flex flex-between top-border-thin bottom-border-thin border-color pt2 pb2'
          )}
        >
          <div className="text-left pl3" style={{ maxWidth: '33.33%' }}>
            {subreddit}
          </div>

          <div className="text-center">
            <TimeAgo timestamp={created_utc} />
          </div>

          <div className="text-right pr3">
            <a
              className="ui-text-color-1 no-underline"
              href={`https://reddit.com${permalink}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Open post in reddit.com"
            >
              {num_comments === 1 ? '1 comment' : `${num_comments} comments`}
            </a>
          </div>
        </div>

        {selftext && (
          <div
            className={cc('PostDetail__self-text UserContent', 'ma3')}
            onClick={handleClicks}
            dangerouslySetInnerHTML={{ __html: parsedSelftext }}
          />
        )}

        {showImagePreview && (
          <>
            <a
              className="block ma3 ui-text-color-1 no-underline"
              href={imageUrl || previewImage.url}
              onClick={ev => {
                ev.preventDefault();
                if (isGallery) {
                  ActionStore.dispatch('open-image-gallery', galleryImages);
                } else {
                  openImageModal(imageUrl || previewImage.url);
                }
              }}
            >
              <img
                src={decodeURIComponent(imageUrl || previewImage.url)}
                className={`PostDetail__preview PostDetail__image-preview push-center block ${
                  isGallery ? 'PostDetail__image-preview--gallery' : ''
                }`}
                alt={title}
                onLoad={() => {
                  this.setState({ imageLoaded: true });
                }}
                style={{ opacity: this.state.imageLoaded ? 1 : 0 }}
              />
              {isGallery && (
                <p className="PostDetail__preview-claimer text-center mt0 mb0 pt1 pb1">
                  Gallery of <b>{galleryItemsAmount} images</b>
                </p>
              )}
            </a>
          </>
        )}

        {youtubeID && (
          <div className="ma3">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <Image
                className="PostDetail__preview block push-center"
                url={`//img.youtube.com/vi/${youtubeID}/hqdefault.jpg`}
              />
            </a>
          </div>
        )}

        {gfycatID && (
          <div className="ma3">
            <div className="PostDetail__iframe-wrap">
              <iframe
                src={`https://gfycat.com/ifr/${gfycatID}`}
                frameBorder="0"
                scrolling="no"
                allowFullScreen
              />
            </div>
          </div>
        )}

        {(vredditURL || imgurVideo) && (
          <>
            <video
              src={vredditURL || imgurVideo}
              autoPlay
              playsInline
              muted
              loop
              width={videoData ? videoData.w : undefined}
              height={videoData ? videoData.h : undefined}
              className="PostDetail__preview mt3 block push-center"
            />
            {vredditURL && (
              <p className="PostDetail__preview-claimer block text-center UserContent mt0 mb0">
                video preview -{' '}
                <a href={url} target="_blank">
                  original
                </a>{' '}
                might have sound
              </p>
            )}
          </>
        )}

        {/* TODO: add button to open "comment on post" modal */}

        {comments.length > 0 ? (
          <div className="anim-reveal-down mt3" onClick={handleClicks}>
            {comments.map(comment => {
              if (comment.id === 'load-more') {
                return null;
              } else {
                const {
                  id,
                  body,
                  author,
                  timeAgo,
                  replies,
                  byOP,
                  vote,
                } = comment;
                return (
                  <PostComment
                    key={id}
                    id={id}
                    body={body}
                    author={author}
                    timeAgo={timeAgo}
                    replies={replies}
                    byOP={byOP}
                    postPermalink={permalink}
                    top
                    expandedReplies={this.props.expandedReplies}
                    vote={vote}
                    reddit={this.reddit}
                  />
                );
              }
            })}
          </div>
        ) : (
          <>
            {loading ? (
              <div className="pt4 pb4">
                <Loading className="push-center" />
              </div>
            ) : (
              <div className="text-center ma3">No Comments Yet</div>
            )}
          </>
        )}
      </div>
    );
  }
}

PostDetail.propTypes = {
  post: PropTypes.object,
  expandedReplies: PropTypes.bool.isRequired,
  reddit: PropTypes.object.isRequired,
};

export default PostDetail;

function handleClicks(ev) {
  const targetEl = ev.target;
  if (targetEl.tagName !== 'A') return;
  const imageURL = checkImageLink(targetEl.href);
  if (!imageURL) return;
  ev.preventDefault();
  openImageModal(imageURL);
  return false;
}

const checkImageLink = url => {
  let imageURL;
  const matching = url.match(
    /\.(svg|jpe?g|png|gifv?)(?:[?#].*)?$|(?:imgur\.com|livememe\.com|reddituploads\.com)\/([^?#\/.]*)(?:[?#].*)?(?:\/)?$/
  );
  if (!matching) return null;
  if (matching[1]) {
    // normal image link
    if (url.indexOf('.gifv') > 0) url = url.replace('.gifv', '.gif');
    if (url.indexOf('imgur.com') >= 0) url = url.replace(/^htt(p|ps):/, '');
    imageURL = url;
  } else if (matching[2]) {
    if (matching[0].slice(0, 5) === 'imgur') {
      // imgur
      imageURL = `//imgur.com/${matching[2]}.jpg`;
    } else if (matching[0].indexOf('livememe.') >= 0) {
      // livememe
      imageURL = `http://i.lvme.me/${matching[2]}.jpg`;
    } else if (matching[0].indexOf('reddituploads.') >= 0) {
      // reddit media
      imageURL = matching.input;
    } else return null;
  } else return null;

  return imageURL.replace(/&amp;/g, '&');
};

function getImgurGifVideo(imageUrl) {
  if (!/(i.imgur.com).*(\.gifv?$)/.test(imageUrl)) {
    return null;
  }
  const extension = imageUrl.includes('.gifv') ? '.gifv' : '.gif';
  return imageUrl.replace(extension, '.mp4');
}

const getYouTubeVideoIDfromURL = url => {
  const matching = url.match(
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  );
  if (!matching) return '';
  else {
    if (matching[2].length === 11) return matching[2];
    else return null;
  }
};

const getGfycatVideoIDfromURL = url => {
  const gfycatBaseURL = 'https://gfycat.com/';
  const index = url.indexOf(gfycatBaseURL);
  if (index < 0) return null;
  return url.substring(gfycatBaseURL.length);
};

function shareUrl(url, title) {
  if (navigator.share) {
    navigator.share({
      title: title,
      url: url,
      text: `"${title}" - shared via Satelite.app`,
    });
  }
}

function getRedditVideoData(media) {
  if (!media || !media.reddit_video || !media.reddit_video) {
    return null;
  }

  return {
    url: media.reddit_video.fallback_url,
    w: media.reddit_video.width,
    h: media.reddit_video.height,
  };
}
