import React from 'react';
import PropTypes from 'prop-types';
import BookmarkIcon from '../Icons/BookmarkIcon';
import Label from '../Label/Label';
import Image from '../Image/Image';
import PinIcon from '../Icons/PinIcon';
import { cc } from '../../modules/styles';
import Icon from '../Icons/Icon';
import ActionStore from '../../modules/ActionStore';

import './PostItem.scss';

/**
 * Item for the list of Posts in the main view
 */

const PostItem = ({
  id,
  title,
  subreddit,
  domain,
  thumbnail = null,
  nsfw = false,
  stickied = false,
  label,
  saved = false,
  likes,
  previewImage,
  isSelected,
  bigThumbnail = true,
  blurNSFW = true,
  galleryItemsAmount = null,
}) => {
  const postUrl = `#post:${id}`;
  const showBigThumbnail = bigThumbnail && previewImage;
  const smallThumbnailSize = 70;
  const isGallery = galleryItemsAmount !== null && galleryItemsAmount > 0;

  return (
    <article
      className={cc(
        'PostItem',
        isSelected ? 'PostItem--selected' : '',
        stickied ? 'PostItem--stickied' : '',
        bigThumbnail ? 'PostItem--big' : 'pa3'
      )}
      style={{ '--image-size': `${smallThumbnailSize}px` }}
    >
      <div className={cc('flex', showBigThumbnail ? 'flex-columns' : '')}>
        {(thumbnail || showBigThumbnail) && (
          <div
            className={cc(
              'PostItem__thumbnail-wrap',
              showBigThumbnail ? 'PostItem__thumbnail-wrap--big' : 'mr3',
              nsfw && blurNSFW ? 'PostItem__warning-wrap' : ''
            )}
            style={{
              flex: !showBigThumbnail
                ? `0 0 ${smallThumbnailSize}px`
                : undefined,
            }}
          >
            <a
              href={postUrl}
              className="PostItem__image-wrap block no-underline relative"
              onClick={ev => {
                ev.preventDefault();
                _openPost(postUrl, id);
              }}
              tabIndex={-1}
            >
              <Image
                url={showBigThumbnail ? previewImage.url : thumbnail}
                description={title}
                className={cc(
                  'PostItem__image no-borders block',
                  !showBigThumbnail ? 'PostItem__image--big' : ''
                )}
                width={
                  showBigThumbnail ? previewImage.width : smallThumbnailSize
                }
                height={
                  showBigThumbnail ? previewImage.height : smallThumbnailSize
                }
              />
              {isGallery && (
                <p className="PostItem__gallery-count mt0 mb0 absolute">
                  <span aria-hidden>+{galleryItemsAmount - 1}</span>
                  <span className="visually-hide">
                    Contains a gallery of {galleryItemsAmount} images.
                  </span>
                </p>
              )}
            </a>
          </div>
        )}

        <div className="w100">
          <a
            href={postUrl}
            className="PostItem__title no-underline bold-text"
            onClick={ev => {
              ev.preventDefault();
              _openPost(postUrl, id);
            }}
          >
            {stickied && <PinIcon className="PostItem__pin-icon" size="1em" />}
            {title}
          </a>
          <div className="PostItem__data">
            <div className="PostItem__domain">{domain}</div>
            <div className="flex flex-wrap flex-y">
              <div
                className="PostItem__subreddit mr1"
                style={{
                  '--theme-color-dark': '35%',
                  '--theme-color-light': '62%',
                }}
              >
                {subreddit}
              </div>
              {label && (
                <div className="mr1">
                  <Label text={label} type="basic" />
                </div>
              )}
              {nsfw && (
                <div>
                  <Label text="nsfw" type="warning" />
                </div>
              )}
              {(saved || likes != null) && (
                <div
                  className="push-right"
                  style={{
                    '--theme-color-dark': '35%',
                    '--theme-color-light': '62%',
                  }}
                >
                  {saved && <BookmarkIcon size="1em" />}
                  {likes != null && (
                    <Icon
                      name="triangle"
                      size="0.9em"
                      rotation={likes ? null : 180}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

PostItem.propTypes = {
  title: PropTypes.string.isRequired,
  subreddit: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  isSelf: PropTypes.bool.isRequired,
  thumbnail: PropTypes.string,
  nsfw: PropTypes.bool,
  stickied: PropTypes.bool,
  saved: PropTypes.bool,
  likes: PropTypes.bool,
  previewImage: PropTypes.object,
};

export default PostItem;

function _openPost(url, id) {
  window.history.pushState(null, '', url);
  ActionStore.dispatch('PostList:fetch-post', id);
}
