import React from 'react';

/**
 * Empty State for the Detail View
 */

const EmptyPostDetail = () => {
  const contentStyle = {
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    textAlign: 'center',
  };

  const tipStyles = {
    marginTop: '2rem',
    fontSize: '.85rem',
    fontStyle: 'italic',
  };

  return (
    <div className="ui-text-color-1 w100 height-100 absolute">
      <div className="absolute" style={contentStyle}>
        <div className="ui-text-color-2" style={{ marginBlockEnd: '4rem' }}>
          Satelite
        </div>
        No Post Selected
        <p style={tipStyles}>
          Tip: Press <kbd>F</kbd> for keyboard shortcuts
        </p>
      </div>
    </div>
  );
};

export default EmptyPostDetail;
