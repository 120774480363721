import React from 'react';
import PropTypes from 'prop-types';
import { showMessage } from '../../actions';

/**
 * Headless component for searching subreddit by name
 */

class SubredditSearcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      results: [],
    };
    this.reddit = props.reddit;
    this.onQueryUpdate = this.onQueryUpdate.bind(this);
    this.reset = this.reset.bind(this);
  }

  onQueryUpdate(ev) {
    const { value } = ev.target;
    this.setState({
      query: value,
    });
    const newQuery = value.replace(/ /g, '');
    if (!newQuery) {
      this.setState({ results: [] });
    } else if (newQuery.length < 3) {
      this.setState({ results: [newQuery] });
    } else {
      this.reddit
        .oauthRequest({
          uri: '/api/search_subreddits',
          method: 'post',
          form: { query: newQuery, include_over_18: true },
        })
        .then(data => {
          const results = data.subreddits.map(subs => subs.name);
          results.push(newQuery);
          this.setState({ results });
        })
        .catch(error => {
          showMessage(`Error searching subreddits: ${error}`);
        });
    }
  }

  reset() {
    this.setState({
      query: '',
      results: [],
    });
  }

  render() {
    return this.props.children({
      text: this.state.query,
      onQueryUpdate: this.onQueryUpdate,
      reset: this.reset,
      results: this.state.results,
    });
  }
}

export default SubredditSearcher;

SubredditSearcher.propTypes = {
  reddit: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};
