import ActionStore from './ActionStore';
import { get, keys, set } from './DataStore';

export function getPreferences() {
  return (
    get(keys.PREFERENCES) ?? {
      theme: 'auto',
      colorL: 0,
      colorD: 0,
      blackDarkBg: 0,
      barsPosition: 'bottom',
      blurNSFW: true,
    }
  );
}

export function setPreference(name, value) {
  const prefs = getPreferences();
  prefs[name] = value;
  set(keys.PREFERENCES, prefs);
  ActionStore.dispatch('update-preferences', prefs);
}
