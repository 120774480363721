import React from 'react';
import PropTypes from 'prop-types';
import { cc } from '../../modules/styles';
import './MenuChannel.scss';

/**
 * Menu Item for a Channel
 */

const MenuChannel = ({ channel, onNavigation, selected }) => {
  const { name, subreddits } = channel;

  return (
    <button
      className={cc(
        'MenuChannel pr3 pt2 pb2',
        selected ? 'MenuChannel--selected pl2' : 'pl3'
      )}
      data-title={name}
      onClick={_ => {
        onNavigation(name);
      }}
    >
      <div
        className={cc(
          'capitalize mb2',
          selected ? 'ui-text-accent' : 'ui-text-color-1'
        )}
      >
        {name}
      </div>

      {subreddits.map(subreddit => (
        <div className="ml4" key={subreddit}>
          <div
            className="MenuChannel__subreddit text-1
            capitalize truncate-text mb2"
          >
            {subreddit}
          </div>
        </div>
      ))}
    </button>
  );
};

export const ChannelPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  subreddits: PropTypes.arrayOf(PropTypes.string).isRequired,
});

MenuChannel.propTypes = {
  channel: ChannelPropTypes.isRequired,
  onNavigation: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default MenuChannel;
