import React from 'react';
import ReactDOM from 'react-dom';
import { SUBTYPE } from './constants';
import { nodes, Actions } from './modules/DOM';
import ImageModal from './components/Modal/ImageModal';
import Modal from './components/Modal/Modal';
import Store, { getSubreddits, getTheme } from './modules/DataStore';
import MainMenu from './components/Menu/MainMenu';
import PostList from './components/Post/PostList';
import PostDetail from './components/PostDetail/PostDetail';
import PostsBar from './components/BottomBar/PostsBar';
import DetailsBar from './components/BottomBar/DetailsBar';
import Portal from './components/Portal/Portal';
import SubredditAdder from './components/SubredditAdder/SubredditAdder';
import ChannelCreator from './components/ChannelCreator/ChannelCreator';
import ActionStore from './modules/ActionStore';
import DefaultData from './modules/DefaultData';
import { clientID } from './config';
import Snoowrap from 'snoowrap';
import './app.scss';
import { setAccentColors, setTheme } from './modules/ThemeSwitcher';
import { Message } from './components/Message/Message';
import { showMessage } from './actions';
import PreferencesMenu from './components/PreferencesMenu/PreferencesMenu';
import { getPreferences } from './modules/Preferences';
import { ImageGallery } from './components/ImageGallery/ImageGallery';
// import { smoothScrollY, ScrollDirection } from '../../JK/src/JK';

class App extends React.Component {
  constructor(props) {
    super(props);

    const initialSelection =
      Store.get(Store.keys.SELECTION) || DefaultData.selection;

    const preferences = getPreferences();

    // Initial Unique Global App State
    this.state = {
      selectedPost: {},
      selection: initialSelection,
      showingImageModal: false,
      showingSubredditAdder: false,
      showingChannelCreator: false,
      showingMessage: false,
      modalImageURL: '',
      username: Store.get(Store.keys.USERNAME) || '',
      expandedReplies: Store.get(Store.keys.EXPANDED_REPLIES) || false,
      bigThumbnails: Store.get(Store.keys.BIG_THUMBNAILS) || false,
      message: {},
      preferences: preferences,
      showingOnMainView: 'posts',
      postsViewId: 0,
      galleryImages: [],
    };
    this.reddit = props.reddit;
    this.onSubredditSelected = this.onSubredditSelected.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  initializeActionListeners() {
    ActionStore.on('switch-subscription', selection => {
      this.setState({
        selection: selection,
        showingOnMainView: 'posts',
      });
      Actions.showPostsView();
      Actions.toggleMenu(false);
    });

    ActionStore.on('open-post', ({ post, transition = false }) => {
      this.setState({ selectedPost: post });
      Actions.toggleMenu(false);
      Actions.showDetailsView(Actions.scrollToTopOfDetailView, transition);
    });

    ActionStore.on('close-post', ({ transition = false }) => {
      this.closeModal();

      Actions.showPostsView(
        () => this.setState({ selectedPost: {} }),
        transition
      );
    });

    ActionStore.on('toggle-expanded-replies', () => {
      this.setState(prevState => ({
        expandedReplies: !prevState.expandedReplies,
      }));
    });

    ActionStore.on('toggle-big-thumbnails', () => {
      this.setState(prevState => ({
        bigThumbnails: !prevState.bigThumbnails,
      }));
    });

    ActionStore.on('update-preferences', newPreferences => {
      this.setState({ preferences: newPreferences });
    });

    ActionStore.on('toggle-menu', newExpanded => {
      Actions.toggleMenu(newExpanded);
    });

    ActionStore.on('open-subreddit', subredditName => {
      this.setState({
        selection: { type: SUBTYPE.SUBREDDIT, name: subredditName },
      });
      Actions.showPostsView();
      Actions.toggleMenu(false);
    });

    ActionStore.on('open-image-modal', imageURL => {
      this.setState({
        showingImageModal: true,
        modalImageURL: imageURL,
      });
    });

    ActionStore.on('close-modal', this.closeModal);

    ActionStore.on('sign-out', () => {
      Store.clear();
      window.location = '';
    });

    ActionStore.on('save-post', postID => {
      this.reddit
        .getSubmission(postID)
        .save()
        .then(() => {
          this.setState(prevState => {
            prevState.selectedPost.saved = true;
            return {
              selectedPost: prevState.selectedPost,
            };
          });
        })
        .catch(error => {
          showMessage(`Error while saving post: ${error}`);
        });
    });

    ActionStore.on('unsave-post', postId => {
      this.reddit
        .getSubmission(postId)
        .unsave()
        .then(() => {
          this.setState(prevState => {
            prevState.selectedPost.saved = false;
            return {
              selectedPost: prevState.selectedPost,
            };
          });
        })
        .catch(error => {
          showMessage(`Error while unsaving post: ${error}`);
          console.error('Error while unsaving post', error);
        });
    });

    ActionStore.on('vote-post', ({ postId, dir }) => {
      this.reddit
        .oauthRequest({
          uri: '/api/vote',
          method: 'post',
          form: { dir: dir, id: `t3_${postId}` },
        })
        .then(() => {
          this.setState(prevState => {
            prevState.selectedPost.likes = dir === 0 ? null : dir > 0;
            return {
              selectedPost: prevState.selectedPost,
            };
          });
        })
        .catch(error => {
          showMessage(`Error while voting post: ${error}`);
          console.error('Error while voting post', error);
        });
    });

    ActionStore.on('open-subreddit-searcher', () => {
      this.setState({ showingSubredditAdder: true });
    });

    ActionStore.on('open-channel-creator', () => {
      this.setState({
        showingChannelCreator: true,
      });
      Actions.toggleMenu(false);
    });

    ActionStore.on('subscription', () => {
      this.setState({});
    });

    ActionStore.on('unsubscription', () => {
      this.setState({});
    });

    ActionStore.on('show-message', message => {
      this.setState({ showingMessage: true, message });
    });

    ActionStore.on('show-main-view', view => {
      this.setState({ showingOnMainView: view });
      // Actions.toggleMenu(false);
    });

    ActionStore.on('reload-posts', () => {
      this.setState(prevState => ({ postsViewId: prevState.postsViewId + 1 }));
    });

    ActionStore.on('open-image-gallery', galleryImages => {
      this.setState({ galleryImages });
    });
  }

  onSubredditSelected(subreddit) {
    ActionStore.dispatch('open-subreddit', subreddit);
    this.closeSubredditSearcher();
  }

  closeModal() {
    if (
      !this.state.showingImageModal &&
      !this.state.showingSubredditAdder &&
      !this.state.showingChannelCreator
    ) {
      return;
    }
    this.setState({
      showingImageModal: false,
      modalImageURL: '',
      showingSubredditAdder: false,
      showingChannelCreator: false,
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.selection.name !== this.state.selection.name) {
      Store.set(Store.keys.SELECTION, this.state.selection);
    }

    if (prevState.expandedReplies !== this.state.expandedReplies) {
      Store.set(Store.keys.EXPANDED_REPLIES, this.state.expandedReplies);
    }

    if (prevState.bigThumbnails !== this.state.bigThumbnails) {
      Store.set(Store.keys.BIG_THUMBNAILS, this.state.bigThumbnails);
    }

    if (prevState.preferences.theme !== this.state.preferences.theme) {
      setTheme(this.state.preferences.theme);
    }

    if (
      prevState.preferences.barsPosition !== this.state.preferences.barsPosition
    ) {
      Actions.setBarsPosition(this.state.preferences.barsPosition);
    }

    if (
      prevState.preferences.colorL !== this.state.preferences.colorL ||
      prevState.preferences.colorD !== this.state.preferences.colorD ||
      prevState.preferences.blackDarkBg !== this.state.preferences.blackDarkBg
    ) {
      setAccentColors(
        this.state.preferences.colorL,
        this.state.preferences.colorD,
        this.state.preferences.blackDarkBg
      );
    }
  }

  componentDidMount() {
    this.initializeActionListeners();

    // Set initial Preferences
    setTheme(getTheme());
    setAccentColors(
      this.state.preferences.colorL,
      this.state.preferences.colorD,
      this.state.preferences.blackDarkBg
    );
    Actions.setBarsPosition(this.state.preferences.barsPosition);

    // Make Initial Requests
    /// Request Data for Menu
    //// Me
    this.reddit
      .getMe()
      .then(me => {
        this.setState({ username: me.name });
        Store.set(Store.keys.USERNAME, me.name);
      })
      .catch(error => {
        showMessage(`Error requesting initial data: ${error}`);
        console.error('Error requesting initial data', error);
      });
  }

  render() {
    const {
      selection,
      selectedPost,
      showingImageModal,
      modalImageURL,
      username,
      expandedReplies,
      bigThumbnails,
      preferences,
      showingOnMainView,
      postsViewId,
      galleryImages,
    } = this.state;

    const showingPosts = showingOnMainView === 'posts';
    const showingPrefs = showingOnMainView === 'prefs';

    return (
      <>
        <Portal mountNode={nodes.menuRoot}>
          <MainMenu
            selection={selection}
            username={username}
            reddit={this.reddit}
          />
        </Portal>

        <Portal mountNode={nodes.menuBarRoot}></Portal>

        <Portal mountNode={nodes.postsRoot}>
          {showingPrefs && (
            <PreferencesMenu
              expandedReplies={expandedReplies}
              bigThumbnails={bigThumbnails}
              preferences={preferences}
            />
          )}

          <PostList
            key={postsViewId}
            showing={showingPosts}
            currentPost={selectedPost}
            subscription={selection}
            reddit={this.reddit}
            bigThumbnails={bigThumbnails}
            blurNSFW={preferences.blurNSFW}
          />
        </Portal>

        <Portal mountNode={nodes.postsBarRoot}>
          <PostsBar
            selected={showingOnMainView}
            name={selection.name}
            type={selection.type}
            isSubscribed={
              selection.type === SUBTYPE.SUBREDDIT &&
              getSubreddits()
                .map(subs => subs.toLowerCase())
                .includes(selection.name.toLowerCase())
            }
            reddit={this.reddit}
          />
        </Portal>

        <Portal mountNode={nodes.detailsRoot}>
          <PostDetail
            expandedReplies={expandedReplies}
            post={selectedPost}
            reddit={this.reddit}
          />
        </Portal>

        <Portal mountNode={nodes.detailsBarRoot}>
          <DetailsBar title={selectedPost.title} />
        </Portal>

        {galleryImages && galleryImages.length > 1 && (
          <Portal mountNode={nodes.modalRoot}>
            <Modal
              onCloseRequest={() => {
                this.setState({ galleryImages: [] });
              }}
              forGallery
            >
              <ImageGallery
                images={galleryImages}
                onCloseRequest={() => {
                  this.setState({ galleryImages: [] });
                }}
              />
            </Modal>
          </Portal>
        )}

        {showingImageModal && (
          <Portal mountNode={nodes.modalRoot}>
            <ImageModal imageURL={modalImageURL} />
          </Portal>
        )}

        {this.state.showingSubredditAdder && (
          <Portal mountNode={nodes.modalRoot}>
            <Modal onCloseRequest={this.closeModal}>
              <SubredditAdder
                title="Go to Subreddit:"
                reddit={this.reddit}
                onComplete={subreddit => {
                  ActionStore.dispatch('open-subreddit', subreddit);
                  this.closeModal();
                }}
              />
            </Modal>
          </Portal>
        )}

        {this.state.showingChannelCreator && (
          <Portal mountNode={nodes.modalRoot}>
            <Modal onCloseRequest={this.closeModal}>
              <ChannelCreator
                reddit={this.reddit}
                onComplete={this.closeModal}
              />
            </Modal>
          </Portal>
        )}

        {this.state.showingMessage && this.state.message && (
          <Portal mountNode={nodes.messageRoot}>
            <Message
              text={this.state.message.text}
              type={this.state.message.type}
              onClosed={() => {
                this.setState({ showingMessage: false, message: {} });
              }}
            />
          </Portal>
        )}
      </>
    );
  }
}

function renderApp(reddit) {
  ReactDOM.render(<App reddit={reddit} />, nodes.appRoot);
}

function renderStartScreen() {
  setAccentColors();
  ReactDOM.render(
    <div
      className="flex flex-x flex-y h100 ml2 mr2"
      style={{ flexDirection: 'column' }}
    >
      <h1 className="mb0">🛰 Satelite (βeta)</h1>
      <i className="text-center mt0">A swift & lean Reddit web client</i>
      <p className="text-center">
        Enjoy a faster, ad-free, and popup-free reddit experience
      </p>
      <a
        href="/auth/"
        className="MainButton no-borders pt2 pb2 w50 text-center no-underline"
        style={{
          color: 'white',
          fontWeight: '700',
          backgroundImage:
            'linear-gradient(90deg, #5D24CE 0%, #C1226F 52.08%, #C58823 100%)',
        }}
      >
        Launch Satelite
      </a>
      <i
        className="text-center UserContent"
        style={{ marginBlockStart: '3rem' }}
      >
        built by <a href="https://berbaquero.com">bernardo baquero stand</a> in
        amsterdam
      </i>
    </div>,
    document.body
  );
}

function initializeRedditClient(refreshToken) {
  return new Snoowrap({
    userAgent: 'satelite',
    clientId: clientID,
    clientSecret: '',
    refreshToken: refreshToken,
  });
}

// INITIALIZE
// Check for reddit API token
const refreshToken = Store.get('rt');
if (refreshToken) {
  // Render app with new reddit client instance
  const reddit = initializeRedditClient(refreshToken);
  reddit.config({ proxies: false });
  renderApp(reddit);
} else {
  // if there's no token, show starting screen
  renderStartScreen();
}

// const withKeyClass = 'with-key';

// function focusOnColumn(columnIndex, withKey = false) {
//   switch (columnIndex) {
//     case 1:
//       Actions.toggleMenu(true);
//       if (withKey) {
//         nodes.menuRoot.classList.add(withKeyClass);
//       }
//       nodes.menuRoot.focus();
//       break;
//     case 2:
//       Actions.toggleMenu(false);
//       if (withKey) {
//         nodes.postsRoot.classList.add(withKeyClass);
//       }
//       nodes.postsRoot.focus();
//       break;
//     case 3:
//       Actions.toggleMenu(false);
//       if (withKey) {
//         nodes.detailsRoot.classList.add(withKeyClass);
//       }
//       nodes.detailsRoot.focus();
//       break;
//   }
// }

// Keyboard Events

// function handleCustomKeys(ev) {
//   const { keyCode, key } = ev;
//   switch (keyCode) {
//     case 27: // Esc
//       closeModal();
//       return;
//   }
//   if (!['1', '2', '3', 'j', 'k', 'h', 'l'].includes(key)) return;
//   switch (key) {
//     case '1':
//       focusOnColumn(1, true);
//       break;
//     case '2':
//       focusOnColumn(2, true);
//       break;
//     case '3':
//       focusOnColumn(3, true);
//       break;
//     case 'j':
//       smoothScrollY(getFocusedView(), 48, 16, ScrollDirection.down);
//       break;
//     case 'k':
//       smoothScrollY(getFocusedView(), 48, 16, ScrollDirection.up);
//       break;
//     case 'h':
//       focusColumn(-1);
//       break;
//     case 'l':
//       focusColumn(1);
//       break;
//   }
// }

function handleShortcutsInit(ev) {
  if (ev.key !== 'f') return;
  // import ZapKeys
  document.body.style.opacity = '.7'; // Add loading "indicator"
  Promise.all([
    import('zapkeys'),
    new Promise(function(resolve, reject) {
      const zapkeysCSS = document.createElement('link');
      zapkeysCSS.rel = 'stylesheet';
      zapkeysCSS.href = 'https://unpkg.com/zapkeys@0.2.0/dist/index.css';
      zapkeysCSS.onload = resolve();
      zapkeysCSS.onerror = reject();
      document.head.appendChild(zapkeysCSS);
    }),
  ])
    .then(([{ ZapKeys }]) => {
      document.body.style.opacity = ''; // Remove loading "indicator"
      const zapkeys = new ZapKeys();
      //   {
      //   onActive: () => {
      //     window.removeEventListener('keypress', handleCustomKeys);
      //   },
      //   onInactive: () => {
      //     window.addEventListener('keypress', handleCustomKeys);
      //   },
      // }
      zapkeys.init({ active: true });
      window.removeEventListener('keypress', handleShortcutsInit);
    })
    .catch(() => (document.body.style.opacity = '')); // Remove loading "indicator"
}

// Initialize shortcuts
// window.addEventListener('keypress', handleCustomKeys);

window.addEventListener('keypress', handleShortcutsInit);

// function getFocusedView() {
//   const { activeElement } = document;
//   if (nodes.menuRoot.contains(activeElement)) return nodes.menuRoot;
//   if (nodes.detailsRoot.contains(activeElement)) return nodes.detailsRoot;
//   return nodes.postsRoot;
// }

// function getColumnIndex(focusedView) {
//   if (focusedView === nodes.menuRoot) return 1;
//   if (focusedView === nodes.postsRoot) return 2;
//   if (focusedView === nodes.detailsRoot) return 3;
// }

// function focusColumn(direction) {
//   const focusedView = getFocusedView();
//   const currentIndex = getColumnIndex(focusedView);
//   const newIndex = currentIndex + direction;
//   if (newIndex < 1 || newIndex > 3) return;
//   focusOnColumn(newIndex, true);
// }
