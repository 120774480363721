import React from 'react';
import PropTypes from 'prop-types';

/**
 * General Image component
 * On load, it fades itself in.
 */

class Image extends React.Component {
  constructor() {
    super();
    this.state = {
      opacity: 0,
    };
    this.reveal = this.reveal.bind(this);
  }

  reveal() {
    this.setState({ opacity: 1 });
  }

  render() {
    const { url, className, style, description, width, height } = this.props;

    return (
      <img
        src={url}
        className={className}
        style={{
          ...style,
          transition: 'opacity 200ms linear',
          opacity: this.state.opacity,
        }}
        alt={description}
        onLoad={this.reveal}
        loading="lazy"
        width={width}
        height={height}
      />
    );
  }
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  description: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Image;
