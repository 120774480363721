import React from 'react';
import PropTypes from 'prop-types';
import { cc } from '../../modules/styles';
import './MenuItem.scss';

/**
 * Menu Item
 */

const MenuItem = ({ name, onClick, selected }) => {
  return (
    <button
      className={cc(
        'MenuItem block pr3 pt2 pb2 no-underline capitalize truncate-text',
        selected ? 'MenuItem--selected pl2 ui-text-accent' : 'pl3'
      )}
      data-name={name}
      onClick={_ => {
        onClick(name);
      }}
    >
      {name}
    </button>
  );
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default MenuItem;
