import React from 'react';
import PropTypes from 'prop-types';
import { cc } from '../../modules/styles';

import './Label.scss';

/**
 * Label for Posts
 */

const Label = ({ text, type = 'basic', className = '' }) => {
  return (
    <div
      className={cc(
        'Label ui-text-color-2 uppercase pl1 pr1',
        type === 'warning' ? 'Label--warning' : '',
        className
      )}
      style={{
        '--theme-bg-color-light': '87%',
        '--theme-bg-color-dark': '14%',
      }}
    >
      {text}
    </div>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['warning', 'basic']),
};

export default Label;
