import React from 'react';
import PropTypes from 'prop-types';
import BookmarkIcon from '../Icons/BookmarkIcon';
import { savePost, unsavePost } from '../../actions';
import './SaveButton.scss';
import { cc } from '../../modules/styles';

/**
 * Button to Save a Post
 */

const SaveButton = ({ postId, saved, className }) => (
  <button
    className={cc('SaveButton simple-button', className)}
    onClick={() => {
      if (saved) {
        unsavePost(postId);
      } else {
        savePost(postId);
      }
    }}
    title={saved ? 'Un-save Post' : 'Save Post'}
  >
    <BookmarkIcon
      className={cc(
        'SaveButton__bookmark-icon',
        saved
          ? 'SaveButton__bookmark-icon--saved'
          : 'SaveButton__bookmark-icon--unsaved'
      )}
    />
  </button>
);

SaveButton.propTypes = {
  postId: PropTypes.string.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default SaveButton;
