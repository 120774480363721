const selection = {
  type: 'subreddit',
  name: 'frontpage',
};

const DefaultData = {
  selection,
};

export default DefaultData;
