import React, { useState } from 'react';
import Icon from '../Icons/Icon';

import './VoteButtons.scss';

function VoteButtons({ className, commentId, reddit, initialVote = 0 }) {
  const [vote, setVote] = useState(initialVote);

  // TODO show error message when voting errors, instead of quietly failing!
  // TODO check if comments have a 'votable' flag from API

  return (
    <div className={className}>
      <div className="flex flex-y">
        <button
          className="VoteButtons reset-button simple-button pa1"
          onClick={() => {
            if (vote === 0 || vote === -1) {
              reddit
                .getComment(commentId)
                .upvote()
                .then(() => {
                  setVote(1);
                })
                .catch(() => {
                  console.error('Could not vote');
                });
            } else {
              reddit
                .getComment(commentId)
                .unvote()
                .then(() => setVote(0))
                .catch(() => {
                  console.error('Could not vote');
                });
            }
          }}
        >
          <Icon
            name="triangle"
            size=".75rem"
            className="VoteButtons__icon ui-text-accent"
            style={{
              strokeWidth: 2,
              fill: vote === 1 ? 'currentColor' : 'transparent',
            }}
          />
        </button>
        <button
          className="VoteButtons reset-button simple-button pa1"
          onClick={() => {
            if (vote === 0 || vote === 1) {
              reddit
                .getComment(commentId)
                .downvote()
                .then(() => {
                  setVote(-1);
                })
                .catch(() => {
                  console.error('Could not vote');
                });
            } else {
              reddit
                .getComment(commentId)
                .unvote()
                .then(() => {
                  setVote(0);
                })
                .catch(() => {
                  console.error('Could not vote');
                });
            }
          }}
        >
          <Icon
            name="triangle"
            rotation={180}
            size=".75rem"
            className="VoteButtons__icon ui-text-accent"
            style={{
              strokeWidth: 2,
              fill: vote === -1 ? 'currentColor' : 'transparent',
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default VoteButtons;
