import React from 'react';
import PropTypes from 'prop-types';
import { cc } from '../../modules/styles';

import './MainButton.scss';

function MainButton({
  onClick,
  className = '',
  children,
  processing = false,
  disabled,
}) {
  return (
    <button
      className={cc(
        'MainButton',
        processing ? 'MainButton--processing' : '',
        'no-borders',
        'pt2 pb2',
        className
      )}
      style={{
        '--theme-color-light': '100%',
        '--theme-color-dark': '100%',
      }}
      onClick={onClick}
      disabled={disabled || processing}
    >
      {children}
    </button>
  );
}

export default MainButton;

MainButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  processing: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
