import { set, keys } from './DataStore';
import { nodes } from './DOM';

const themes = ['auto', 'light', 'dark'];

const rootNode = window.document.documentElement;

function getCurrentTheme() {
  return rootNode.dataset.theme;
}

export function getNextTheme() {
  const currentIndex = themes.indexOf(getCurrentTheme());
  if (currentIndex >= themes.length - 1) {
    return themes[0];
  } else {
    return themes[currentIndex + 1];
  }
}

export function setTheme(theme, pureBlack = false) {
  rootNode.dataset.theme = theme;
  set(keys.THEME, theme);

  setThemeColor(theme, pureBlack);
}

const darkModeMQ = window.matchMedia('(prefers-color-scheme: dark)');

function setThemeColor(theme, pureBlack) {
  switch (theme) {
    case 'light':
      setThemeMeta('white');
      darkModeMQ.removeEventListener('change', onDarkModeChange);
      break;
    case 'dark':
      setDarkMode(pureBlack);
      darkModeMQ.removeEventListener('change', onDarkModeChange);
      break;
    case 'auto':
      onDarkModeChange();
      darkModeMQ.addEventListener('change', onDarkModeChange);
      break;
  }
}

function setThemeMeta(content) {
  document.head
    .querySelector('meta[name="theme-color"]')
    .setAttribute('content', content);
}

function onDarkModeChange() {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    setDarkMode();
  } else {
    setThemeMeta('white');
  }
}

function setDarkMode(pureBlack) {
  // if (document.documentElement.hasAttribute('data-black') || pureBlack) {
  setThemeMeta('black');
  // } else {
  // setThemeMeta('#0D1117');
  // }
}

export const accentColorsLight = ['red', 'purple', 'blu'];
export const accentColorsDark = ['red', 'purple', 'green', 'blu'];
export const blackDarkBgs = ['n', 'y'];

export function setAccentColors(indexLight = 0, indexDark = 0, indexBg = 0) {
  const light = accentColorsLight[indexLight];
  const dark = accentColorsDark[indexDark];
  nodes.root.style = `
  --accent-h--l: var(--${light}-h);
  --accent-s--l: var(--${light}-s);
  --accent-h--d: var(--${dark}-h);
  --accent-s--d: var(--${dark}-s);
  `;
  nodes.root.dataset.blackDark = blackDarkBgs[indexBg];
}
